import axios from "axios";
import Const from "../constant";

export interface QAtype {
    question: string;
    answer: string;
}

export const API = axios.create({
    
})

export const checkVocRequest = async (kakao_access_token:string, reception_number: string) => {
    const url = `/v1/auth/validation/`;
    API.defaults.withCredentials = true;

    try{
        const data = {
            access_token: kakao_access_token,
            reception_number: reception_number
        }
        const response = await API.post(url, data);       
        return response.data.code;
    }
    catch(err)
    {
        return '400';     
    }
}

export const createReviewRequest = async (kakao_access_token:string, reception_number: string, qalist: QAtype[]) => {
    const url = `/v1/voc/create/`;
    API.defaults.withCredentials = true;

    try{
        const data = {
            access_token: kakao_access_token,
            reception_number: reception_number,
            user_answers: qalist
        }
        const response = await API.post(url, data);       
        return response.data.code;
    }
    catch(err)
    {
        return '400';     
    }
}

API.interceptors.response.use(function (response) {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행  

    return response;

  }, function (error) {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행
    return Promise.reject(error);
});

