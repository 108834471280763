import styled from "styled-components";

import {
    Title,
    Number
} from '../atoms';

import {
    SelectList
} from '../molecules';

const Container = styled.div`
    width: 100%;
    height: 352.5px;
    background: #08222F;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 35px 13px;
    flex-direction: column;
    overflow: hidden;
`;

const RowWrapper = styled.div<{top: string, bottom: string}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.bottom}px;
    margin-top: ${(props) => props.top}px;
`;

interface SelectProps {
    title: string;
    number: string;
    answerList: string[];
    onSelect: (value: string) => void;    
}

export function Select({
    title,
    number,
    answerList,
    onSelect
}: SelectProps){
    return (
        <Container>
            <RowWrapper top="0" bottom="24">
                <Title 
                    text={title}
                />
                <Number 
                    text={number}
                />
            </RowWrapper>
            <SelectList 
                answerList={answerList}
                onSelect={(value) => {
                    onSelect(value);
                }}
            />
        </Container>
    );
}