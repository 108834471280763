import qs from 'qs';
import { API } from '../apis/ReviewApi';

import { useEffect } from "react";
import { 
    useLocation, 
    useNavigate 
} from "react-router-dom";

import { 
    setLocalStorage 
} from '../utilities/localstorage';

import Const from '../constant';

type KakaoTokenType = {
    access_token: string;
};

function Login(){
    
    const location = useLocation();
    const navigate = useNavigate();

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const code = query.code;
    const state = query.state;
    // const access_token = query.access_token;

    const getKakaoToken = () => {
        API({
            method: 'post',
            params: {
                client_id: Const.REST_API_KEY,
                code,
                grant_type: 'authorization_code'
            },
            url: 'https://kauth.kakao.com/oauth/token',
            withCredentials: false,
        }).then((response) => {
            const { access_token: kkoAccessToken }: KakaoTokenType = response.data;
            //token localStorage에 저장 
            setLocalStorage('kakao_access_token', kkoAccessToken);
            navigate(`${state}`);
            
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(code)
        {
            getKakaoToken();
        }
    }, [])

    return <></>;
}

export default Login;