import styled from "styled-components";

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 17.5px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.017em;
    color: #52F6FF;
`;

interface NumberProps {
    text: string;
}

export function Number({
    text
}: NumberProps){
    return <Text>{text}</Text>;
}