import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #CCCCCC;
`;

const Imoji = styled.img`
    margin-left: 2.5px;
    margin-top: -2px;
    width: 10px;
`;

interface LabelProps{
    happy: boolean;
    text: string;
}

export function Label({
    happy,
    text
}: LabelProps){
    return (
        <Wrapper>
            <Text>{text}</Text>
            { happy ? <Imoji src={require('../../styles/assets/happy.png')} /> : <Imoji src={require('../../styles/assets/unhappy.png')} />}
        </Wrapper>

    );
}