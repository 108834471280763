import styled from "styled-components";

import {
    Title,
    SubTitle,
    Number
} from '../atoms';

import {
    InputTextArea
} from '../molecules';


const Container = styled.div`
    width: 100%;
    height: 232.5px;
    background: #08222F;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 35px 13px;
    flex-direction: column;
    overflow-x: hidden;
`;

const RowWrapper = styled.div<{top: string, bottom: string}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.bottom}px;
    margin-top: ${(props) => props.top}px;
`;

interface InputTextProps {
    title: string;
    number: string;
    subTitle: string;
    subTitleImage: number;
    onTextInput: (text: string) => void;
}

export function InputText({
    title,
    number,
    subTitle,
    subTitleImage,
    onTextInput
}: InputTextProps){
    return (
        <Container>
            <RowWrapper top="0" bottom="12">
                <Title 
                    text={title}
                />
                <Number 
                    text={number}
                />
            </RowWrapper>
            <RowWrapper top="0" bottom="23">
                <SubTitle 
                    image={subTitleImage}
                    text={subTitle}
                />
            </RowWrapper>

            <InputTextArea 
                onValueChange={(text) => {
                    onTextInput(text)
                }}
            />
        </Container>
    );
}