import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from './Login';

import Review from "./Review";
import Complete from './Complete';
import Error from './Error';


// login
// review
// complete



function Router(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:reception_number" element={<Review></Review>} /> 
                <Route path="/complete" element={<Complete></Complete>} />    
                <Route path="/error" element={<Error></Error>} />  
                <Route path="/auth/callback" element={<Login></Login>} /> 
            </Routes>
        </BrowserRouter>
    );
}

export default Router;