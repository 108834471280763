import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;


const Image = styled.img`
    width: 211px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 44px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #FFFFFF;
    text-align: center;
    margin-top: 29px;
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #DEE0E2;
    margin-top: 44px;
`;

function Complete() {
    return (
        <Container>
            <Image src={require('../styles/assets/404.png')} />
            <Title>잘못된<br/>접근입니다</Title>     
        </Container>
    );
}

export default Complete;