export function setLocalStorage(key: string, data?: string){
    if(data)
    {
        localStorage.setItem(key, data);
    }
    else
    {
        localStorage.removeItem(key);
    }

}

export function getLocalStorage(key: string){
    return localStorage.getItem(key);
}