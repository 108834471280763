import { useState } from "react";
import styled from "styled-components";

import {
    SelectButton
} from '../atoms'

const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 331px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;

interface SelectListProps {
    answerList: string [];    
    onSelect: (value: string) => void;
}

export function SelectList({
    answerList,
    onSelect
}: SelectListProps){
    const [selected, setSelected] = useState<number>();

    return (
        <List>
            {
                answerList.map((answer, index) => {
                    return (
                        <SelectButton 
                            text={answer}
                            active={selected === index ? true : false}
                            onSelect={() => {
                                setSelected(index);
                                onSelect(answerList[index]);
                            }}
                        />
                    );
                })
            }
        </List>
    );
}