import styled from "styled-components";

interface SelectButtonProps {
    text: string;
    active: boolean;
    onSelect: () => void;
}

const Button = styled.div<{active: boolean}>`
    width: 331px;
    height: 34px;
    background: ${(props) => props.active ? '#000000' : '#061821'};
    color: ${(props) => props.active ? '#52F6FF' : '#CCCCCC'};
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid #2A3E49;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
`;

interface SelectButtonProps {
    text: string;
    active: boolean;
    onSelect: () => void;
}

export function SelectButton({
    text,
    active,
    onSelect
}:SelectButtonProps){
    return (
        <Button
            active={active}
            onClick={onSelect}
        >{text}</Button>
    );
}