import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Const from '../constant';

import { 
    getLocalStorage 
} from '../utilities/localstorage';

import { 
    Score,
    InputText,
    Select
} from '../components/organisms';

import { 
    checkVocRequest,
    QAtype,
    createReviewRequest
} from '../apis/ReviewApi';

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${(props) => props.theme.textColor};
    flex-direction: column;
    padding: 42px 0px;
    overflow-x: hidden;
`;

const Logo = styled.img`
    width: 49px;
`;

const RowWrapper = styled.div<{bottom: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: ${(props) => props.bottom}px;
`;

const ScoreQWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 200.5px;
    flex-direction: column;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 272.5px;
    flex-direction: column;
`;

const SelectWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 352.5px;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 44px;
    display: flex;
    align-items: center;
    letter-spacing: -0.0085em;
    color: #FFFFFF;
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.5px;
    /* or 125% */
    display: flex;
    align-items: center;
    letter-spacing: -0.0085em;
    color: #DEE0E2; 
    margin-top: 10px;
    margin-bottom: 65px;
`;

const BoxLeft = styled.div`
    width: 50%;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 60px;
`;

const BoxRight = styled.div`
    width: 50%;
    height: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 0px;
`;

const Image = styled.img`
    max-width: 159.67px;
`;

const Error = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 17.5px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #FF522D;
    margin-left: 10px;
    margin-top: 5.5px;
`;

const ErrorIcon = styled.img`
    margin-right: 5px;
    width: 9px;
`

const SubmitButton = styled.div`
    width: 100%;
    height: 52px;
    background: #5573FF;
    border-radius: 10px;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 0px;
`;

interface InputType {
    title: string;
    number: string;
    subTitle: string;
    subTitleImage: number;
    answer: string;
}

interface ScoreType {
    title: string;
    number: string;
    left: string;
    leftImage: boolean;
    right: string;
    rightImage: boolean;
    answer: string;
}

interface SingleSelectType {
    title: string;
    number: string;
    answerList: string[];
    answer: string;
}

function Review(){
    const { reception_number } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [ kakaoAccessToken, setKakaoAccessToken ] = useState<string>();

    const [ q1, setQ1 ] = useState<ScoreType>({
        title: '솔닥 만족도를 알려주세요',
        number: '1 / 9',
        left: '매우 불만족',
        leftImage: false,
        right: '매우 만족',
        rightImage: true,
        answer: ''
    });

    const [ q2, setQ2 ] = useState<ScoreType>({
        title: '솔닥 배송 서비스에 대해 얼마나 만족하셨나요?',
        number: '2 / 9',
        left: '매우 불만족',
        leftImage: false,
        right: '매우 만족',
        rightImage: true,
        answer: ''
    });

    const [ q3, setQ3 ] = useState<InputType>({
        title: '배송 서비스 점수의 이유를 알려주세요',
        number: '3 / 9',
        subTitle: '구체적으로 알려주시면 솔닥이 개선해 나가겠습니다',
        subTitleImage: 0,
        answer: ''
    });

    const [ q4, setQ4 ] = useState<ScoreType>({
        title: '솔닥에서 만난 의사 선생님은 얼마나 만족스러우셨나요?',
        number: '4 / 9',
        left: '매우 불만족',
        leftImage: false,
        right: '매우 만족',
        rightImage: true,
        answer: ''
    });

    const [ q5, setQ5 ] = useState<ScoreType>({
        title: '솔닥을 이용할 때 어렵지는 않으셨나요?',
        number: '5 / 9',
        left: '매우 어려웠어요',
        leftImage: false,
        right: '전혀 어렵지 않았어요',
        rightImage: true,
        answer: ''
    });

    const [ q6, setQ6 ] = useState<ScoreType>({
        title: '주변에 솔닥을 추천할 의향이 있으신가요?',
        number: '6 / 9',
        left: '전혀 추천하지 않아요',
        leftImage: false,
        right: '매우 추천해요',
        rightImage: true,
        answer: ''
    });

    const [ q7, setQ7 ] = useState<ScoreType>({
        title: '솔닥을 다시 사용할 의향이 있으신가요?',
        number: '7 / 9',
        left: '전혀 없어요',
        leftImage: false,
        right: '매우 있어요',
        rightImage: true,
        answer: ''
    });

    const [ q8, setQ8 ] = useState<InputType>({
        title: '자유롭게 솔닥에 건의하고 싶은 내용을 남겨주세요!',
        number: '8 / 9',
        subTitle: '더 나은 솔닥을 만드는데 소중히 사용하겠습니다',
        subTitleImage: 1,
        answer: ''
    });

    const [ q9, setQ9 ] = useState<SingleSelectType>({
        title: '솔닥을 사용하게 된 경로는 무엇인가요?',
        number: '9 / 9',
        answerList: [
            "1. 지인 추천",
            "2. 포털 검색",
            "3. 블로그 / 카페",
            "4. 인스타그램 / 페이스북 광고", 
            "5. 재진 리마인드 알림톡",
            "6. 기타"
        ],
        answer: ''
    })

    const [ q1Err, setQ1Err ] = useState(false);
    const [ q2Err, setQ2Err ] = useState(false);
    const [ q3Err, setQ3Err ] = useState(false);
    const [ q4Err, setQ4Err ] = useState(false);
    const [ q5Err, setQ5Err ] = useState(false);
    const [ q6Err, setQ6Err ] = useState(false);
    const [ q7Err, setQ7Err ] = useState(false);
    const [ q8Err, setQ8Err ] = useState(false);
    const [ q9Err, setQ9Err ] = useState(false);

    const submit = async () => {
        if(q1.answer === '')
        {
            setQ1Err(true);
        }
        else if(q2.answer === '')
        {
            setQ2Err(true);
        }
        else if(q3.answer === '')
        {
            setQ3Err(true);
        }
        else if(q4.answer === '')
        {
            setQ4Err(true);
        }
        else if(q5.answer === '')
        {
            setQ5Err(true);
        }
        else if(q6.answer === '')
        {
            setQ6Err(true);
        }
        else if(q7.answer === '')
        {
            setQ7Err(true);
        }
        else if(q8.answer === '')
        {
            setQ8Err(true);
        }
        else if(q9.answer === '')
        {
            setQ9Err(true);
        }
        else
        {
            //제출하기 
            let qaList: QAtype[] = [
                {question: q1.title, answer: q1.answer},
                {question: q2.title, answer: q2.answer},
                {question: q3.title, answer: q3.answer},
                {question: q4.title, answer: q4.answer},
                {question: q5.title, answer: q5.answer},
                {question: q6.title, answer: q6.answer},
                {question: q7.title, answer: q7.answer},
                {question: q8.title, answer: q8.answer},
                {question: q9.title, answer: q9.answer},
            ];

            const code = await createReviewRequest(kakaoAccessToken as string, reception_number as string, qaList);            
            codeHandler(code);            
        }

    }

    const checkVoc = async (kakao_access_token:string) => {
        const code = await checkVocRequest(kakao_access_token, reception_number as string); 
        codeHandler(code);
    }

    const codeHandler = (code: string) => {
        if(code === '200')
        {
            
        }   
        else if(code == '201')
        {
            navigate('/complete')
        }
        else if(code === '400')
        {
            const path = `${location.pathname}`;       
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${Const.REST_API_KEY}&redirect_uri=${Const.REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
        }
        else if(code === '401')
        {
            navigate('/error')
        }
        else if(code === '402')
        {
            navigate('/error')
        }
        else if(code === '403')
        {
            navigate('/complete')
        }
    }

    useEffect(() => {
        const kakao_access_token = getLocalStorage('kakao_access_token');
        
        if(kakao_access_token)
        {
            setKakaoAccessToken(kakao_access_token);
            //검증 
            checkVoc(kakao_access_token);
        }
        else
        {
            const path = `${location.pathname}`;       
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${Const.REST_API_KEY}&redirect_uri=${Const.REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
        }
       
    }, [])

    return (
        <Container>
            <Logo src={require('../styles/assets/soldoc_logo.png')} />
            <RowWrapper bottom='70'>
                <BoxLeft>
                    <Title>솔닥을<br/>써보니<br/>어떠셨나요?</Title>                 
                </BoxLeft>
                <BoxRight>
                    <Image src={require('../styles/assets/review_img.png')} />
                </BoxRight>                
            </RowWrapper>
            <SubTitle>솔닥을 이용해주셔서 정말 감사해요!<br/><br/>
                    솔닥을 사용하면서 느낀 점을 솔직하게 남겨주시면<br/>
                    더 나은 솔닥을 만드는 일에 소중히 사용할게요.
            </SubTitle>
            <ScoreQWrapper>
                <Score 
                    title={q1.title}
                    number={q1.number}
                    left={q1.left}
                    leftImage={q1.leftImage}
                    right={q1.right}
                    rightImage={q1.rightImage}
                    onButtonClick={(score) => {
                        setQ1({...q1, answer: score})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q1Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>}                
            </ScoreQWrapper>
            <ScoreQWrapper>
                <Score 
                    title={q2.title}
                    number={q2.number}
                    left={q2.left}
                    leftImage={q2.leftImage}
                    right={q2.right}
                    rightImage={q2.rightImage}
                    onButtonClick={(score) => {
                        setQ2({...q2, answer: score})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q2Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </ScoreQWrapper>              
            <InputWrapper>
                <InputText
                    title={q3.title}
                    number={q3.number}
                    subTitle={q3.subTitle}
                    subTitleImage={q3.subTitleImage}
                    onTextInput={(text) => {
                        setQ3({...q3, answer: text})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                ></InputText>
                {q3Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </InputWrapper>
            <ScoreQWrapper>
                <Score 
                    title={q4.title}
                    number={q4.number}
                    left={q4.left}
                    leftImage={q4.leftImage}
                    right={q4.right}
                    rightImage={q4.rightImage}
                    onButtonClick={(score) => {
                        setQ4({...q4, answer: score})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q4Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </ScoreQWrapper>
            <ScoreQWrapper>
                <Score 
                    title={q5.title}
                    number={q5.number}
                    left={q5.left}
                    leftImage={q5.leftImage}
                    right={q5.right}
                    rightImage={q5.rightImage}
                    onButtonClick={(score) => {
                        setQ5({...q5, answer: score})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q5Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </ScoreQWrapper>
            <ScoreQWrapper>
                <Score 
                    title={q6.title}
                    number={q6.number}
                    left={q6.left}
                    leftImage={q6.leftImage}
                    right={q6.right}
                    rightImage={q6.rightImage}
                    onButtonClick={(score) => {
                        setQ6({...q6, answer: score})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q6Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </ScoreQWrapper>

            <ScoreQWrapper>
                <Score 
                    title={q7.title}
                    number={q7.number}
                    left={q7.left}
                    leftImage={q7.leftImage}
                    right={q7.right}
                    rightImage={q7.rightImage}
                    onButtonClick={(score) => {
                        setQ7({...q7, answer: score})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q7Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </ScoreQWrapper>
            <InputWrapper>
                <InputText
                    title={q8.title}
                    number={q8.number}
                    subTitle={q8.subTitle}
                    subTitleImage={q8.subTitleImage}
                    onTextInput={(text) => {
                        setQ8({...q8, answer: text})
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                ></InputText>
                {q8Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </InputWrapper>
            <SelectWrapper>
                <Select 
                    title={q9.title}
                    number={q9.number}
                    answerList={q9.answerList}
                    onSelect={(value) => {        
                        setQ9({...q9, answer: value})                
                        setQ1Err(false);
                        setQ2Err(false);
                        setQ3Err(false);
                        setQ4Err(false);
                        setQ5Err(false);
                        setQ6Err(false);
                        setQ7Err(false);
                        setQ8Err(false);
                        setQ9Err(false);
                    }}
                />
                {q9Err && <Error>
                    <ErrorIcon src={require('../styles/assets/error.png')} />
                    필수 질문에 답변해주세요 !
                </Error>} 
            </SelectWrapper>
            <RowWrapper bottom='84.5'>
                <SubmitButton
                    onClick={submit}
                >의견 제출하기</SubmitButton>
            </RowWrapper>
        </Container>
    );
}

export default Review;