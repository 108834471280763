import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #CCCCCC;
`;

const Image = styled.img`
    margin-left: 3px;
    width: 12px;
`;

interface SubTitleProps {
    text: string;
    image: number;
}

export function SubTitle({
    text,
    image
}: SubTitleProps){
    return (
        <Wrapper>
            <Text>{text}</Text>
            {
                image === 0 && <Image src={require('../../styles/assets/rocket.png')} />
            }
            {
                image === 1 && <Image src={require('../../styles/assets/laugh.png')} />
            }
        </Wrapper>
    );
}