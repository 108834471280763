import { FormEvent } from "react";
import styled from "styled-components";

const TextArea = styled.textarea`
    width: 331px;
    height: 100px;
    background: #061821;
    border: 1px solid #2A3E49;
    border-radius: 10px;
    resize: none;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.017em;
    color: #CCCCCC;
    padding: 14px 14px;
    overflow-x: hidden;
`;

interface InputTextAreaProps{
    onValueChange: (text: string) => void;
}

export function InputTextArea({
    onValueChange
}:InputTextAreaProps){

    const onTextChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;
        
        onValueChange(value);
    }   

    return (
        <TextArea 
            onChange={onTextChange}
        />
    )
}