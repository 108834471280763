import styled from "styled-components";

import {
    Title,
    Label,
    Number
} from '../atoms';

import {
    ScoreButtonList
} from '../molecules';

const Container = styled.div`
    width: 100%;
    height: 160.5px;
    background: #08222F;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 35px 13px;
    flex-direction: column;
    overflow-x: hidden;
`;

const RowWrapper = styled.div<{top: string, bottom: string}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.bottom}px;
    margin-top: ${(props) => props.top}px;
`;

interface ScoreProps {
    title: string;
    number: string;
    left: string;
    leftImage: boolean;
    right: string;
    rightImage: boolean;
    onButtonClick: (score: string) => void;
}

export function Score({
    title,
    number,
    left,
    leftImage,
    right,
    rightImage,
    onButtonClick
}: ScoreProps){
    return (
        <Container>
            <RowWrapper top="0" bottom="21.5">
                <Title 
                    text={title}
                />
                <Number 
                    text={number}
                />
            </RowWrapper>
            <ScoreButtonList 
                onSelectScore={(score) => {onButtonClick(score.toString())}}
            />
            <RowWrapper top="16" bottom="0">
                <Label 
                    happy={leftImage}
                    text={left}
                />
                <Label 
                    happy={rightImage}
                    text={right}
                />
            </RowWrapper>
        </Container>
    );
}