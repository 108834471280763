import styled from "styled-components";

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.5px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #FFFFFF;
`;

interface TitleProps {
    text: string;
}

export function Title({
    text
}:TitleProps){
    return <Text>{text}</Text>;
}