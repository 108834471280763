// button list, on off 제어 
import { useState } from "react";
import styled from "styled-components";

import {
    ScoreButton
} from '../atoms'

const Wrapper = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 1.265px;
    grid-template-columns: repeat(10, 1fr);
    width: 330.9px;
`;

interface ScoreButtonListProps {
    onSelectScore: (score: number) => void;
}
export function ScoreButtonList({
    onSelectScore
}: ScoreButtonListProps){
    const [ selectedScore, setSelectedScore ] = useState(0);

    const rendering = () => {
        const result = []; 
        for(let i = 1; i <= 10; i++)
        {
            if(i <= selectedScore)
            {
                result.push(<ScoreButton
                    onSelect={() => {
                        setSelectedScore(i)
                        onSelectScore(i);
                    }}
                    key={i}
                    score={i}
                    active={true}
                ></ScoreButton>)
            }
            else
            {
                result.push(<ScoreButton
                    onSelect={() => {
                        setSelectedScore(i)
                        onSelectScore(i);
                    }}
                    key={i}
                    score={i}
                    active={false}
                ></ScoreButton>)
            }
        }    
        return result;
    }

    return (
        <Wrapper>
            {
                rendering()
            }
        </Wrapper>
    );
}