import styled from "styled-components";

const Button = styled.div<{bgColor: string, active: boolean}>`
    background-color: ${(props) => props.active ? props.bgColor : '#08222F'};
    border-radius: 5px;
    border: ${(props) => props.active ? '' : '1px solid #777F84'}; 
    width: 31.9px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 17.5px;
    letter-spacing: -0.0085em;
    color: #FFFFFF;
    cursor: pointer;
`;

interface ScoreButtonProps {
    score: number;
    active: boolean;
    onSelect: () => void;
}

export function ScoreButton({
    score,
    active,
    onSelect
}: ScoreButtonProps){
    return (
        <>
            { score === 1 && <Button onClick={onSelect} bgColor="#5573FF" active={active}>{score}</Button>}
            { score === 2 && <Button onClick={onSelect} bgColor="#5585FF" active={active}>{score}</Button>}
            { score === 3 && <Button onClick={onSelect} bgColor="#5596FF" active={active}>{score}</Button>}
            { score === 4 && <Button onClick={onSelect} bgColor="#56A7FF" active={active}>{score}</Button>}
            { score === 5 && <Button onClick={onSelect} bgColor="#58B7FF" active={active}>{score}</Button>}
            { score === 6 && <Button onClick={onSelect} bgColor="#58C5FF" active={active}>{score}</Button>}
            { score === 7 && <Button onClick={onSelect} bgColor="#57D3FF" active={active}>{score}</Button>}
            { score === 8 && <Button onClick={onSelect} bgColor="#56E0FF" active={active}>{score}</Button>}
            { score === 9 && <Button onClick={onSelect} bgColor="#53EAFF" active={active}>{score}</Button>}
            { score === 10 && <Button onClick={onSelect} bgColor="#52F6FF" active={active}>{score}</Button>}
        </>

    )
}