import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;


const Image = styled.img`
    width: 211px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 44px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #FFFFFF;
    text-align: center;
    margin-top: 29px;
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
    color: #DEE0E2;
    margin-top: 20px;
    text-align: center;
`;

function Complete() {
    return (
        <Container>
            <Image src={require('../styles/assets/final.png')} />           
            <Title>의견이<br/>제출되었어요</Title>
            <SubTitle>좋은 의견 보내주셔서 감사합니다!<br/>더 나은 솔닥이 되어서 다시 찾아뵐게요</SubTitle>            
        </Container>
    );
}

export default Complete;